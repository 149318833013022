$(document).ready(function() {
	$('.carousel[data-type="multi"] .item').each(function(){
		var next = $(this).next();
		if (!next.length) {
			next = $(this).siblings(':first');
		}
		next.children(':first-child').clone().appendTo($(this));
	  
		for (var i=1;i<2;i++) {
			next=next.next();
			if (!next.length) {
				next = $(this).siblings(':first');
			}
			next.children(':first-child').clone().appendTo($(this));
		}
	});

	// show and hide diffrent steps in modal query dialog
	$('.move-steps').on('click', function(e){
		e.preventDefault();
		if ($('.modal-body-step-1').is(':visible')) {  //move step button to step 2
			if ($('#mod-form').valid()) {
				$('.modal-body-step-1').hide();
				$('.modal-body-step-2, .back-steps').show();
				/* background-color : #f47c36  is for IE */
				$('.modal-steps-query i.step_1, .modal-steps-query span.step_2').css({ "background-color": "rgb(242, 98, 39, 1)", "background-color" : "#f47c36" });
			}
		} else {   //move step button to step 3
			if ($('#mod-form').valid()) {
				$('.modal-body-step-2, .move-steps').hide();
				$('.modal-body-step-3, .price-section, .submit-query-btn, .back-steps').show();
				/* background-color : #f47c36  is for IE */
				$('.modal-steps-query i.step_2, .modal-steps-query span.step_3').css({ "background-color": "rgb(242, 98, 39, 1)", "background-color" : "#f47c36" });
				$('.price-section').css({ "display": "inline-block" });
				$('#service').val('');
			}
		}
	});

	// show and hide diffrent steps in modal query dialog
	$('.back-steps').on('click', function(e){
		e.preventDefault();
		if ($('.modal-body-step-2').is(':visible')) {  //move step button to step 1
			$('.modal-body-step-2').hide();
			$('.modal-body-step-1').show();
			$('.back-steps').hide();
			/* background-color : #f47c36  is for IE */
			$('.modal-steps-query i.step_1, .modal-steps-query span.step_2').css({ "background-color": "rgb(183, 183, 183)", "background-color" : "#b7b7b7" });
		} else {   //move step button to step 2
			$('.modal-body-step-3, .submit-query-btn').hide();
			$('.modal-body-step-2, .move-steps').show();
			$('.price-section').hide();
			/* background-color : #f47c36  is for IE */
			$('.modal-steps-query i.step_2, .modal-steps-query span.step_3').css({ "background-color": "rgb(183, 183, 183)", "background-color" : "#b7b7b7" });
			$('.price-section').css({ "display": "inline-block" });
		}
	});

	// change operations images on hover
	$('.new-request').hover(function() {
		$('.new-request img').attr('src', 'css/images/request-icon-hover.png');
		},
        function () { $('.new-request img').attr("src", 'css/images/request-icon.png');
	});
	$('.trace').hover(function() {
		$('.trace img').attr('src', 'css/images/trace-icon-hover.png');
		},
        function () { $('.trace img').attr("src", 'css/images/trace-icon.png');
	});
	$('.redirect').hover(function() {
		$('.redirect img').attr('src', 'css/images/redirect-icon-hover.png');
		},
        function () { $('.redirect img').attr("src", 'css/images/redirect-icon.png');
	});
	$('.calculate').hover(function() {
		$('.calculate img').attr('src', 'css/images/calculate-icon-hover.png');
		},
        function () { $('.calculate img').attr("src", 'css/images/calculate-icon.png');
	});
	$('.report').hover(function() {
		$('.report img').attr('src', 'css/images/report-icon-hover.png');
		},
        function () { $('.report img').attr("src", 'css/images/report-icon.png');
	});

	$('#wrap').affix({
	    offset: {     
	      top: $('#wrap').offset().top
		}
	}).on('affix.bs.affix', function () {
        $('#wrap').addClass("fix-nav");
    }).on('affix-top.bs.affix', function () {
        $('#wrap').removeClass("fix-nav");
    });

    if ($('#wrap').hasClass('affix')) {
    	$('#wrap').addClass('fix-nav');
    }
});