jQuery(document).ready(function($){

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	 });

	$.validator.addMethod("minDate", function(value, element) {
			var today = new Date();
			var yesterday = new Date(today);
			var curDate = yesterday.setDate(today.getDate() - 1);
			var inputDate = new Date(value);
			if ($('#slow_shipment_date').hasClass('show'))  {
				if (inputDate > curDate) {
					return true;
				}
				return false;
			} else {
			return true;
		}
	}, "Невалидна дата!");
	
	//logistics-form
	$("#mod-form").validate({
		ignore: ":hidden",
		rules: {
			"sender": {required: true, maxlength: 255},
			"sender_phone1": {required: true, maxlength: 255, number: true},
			"sender_town": {required: true, maxlength: 255},
			"sender_note": {maxlength: 255},
			
			"recipient": {required: true, maxlength: 255},
			"recipient_phone1": {required: true, maxlength: 255, number: true},
			"recipient_town": {required: true, maxlength: 255},
			"recipient_note": {maxlength: 255},
			
			"service": {required: true},
			"package": {required: true, maxlength: 100},
			"package_count": {required: true, number: true},
			"weight": {required: true, number: true},
			"contents": {required: true, maxlength: 255},
			"width": {number: true},
			"height": {number: true},
			"depth": {number: true},
			"slow_shipment_date": {minDate: true}
		},
		messages: {
			"sender": {required: "Моля въведете име.", maxlength: "Въведохте повече от 255 символа."},
			"sender_phone1": {required: "Моля въведете телефонен номер.", number: "Телефонът трябва да съдържа само цифри."},
			"sender_town": {required: "Моля въведете град.", maxlength: "Въведохте повече от 255 символа."},
			"sender_note": {maxlength: "Въведохте повече от 255 символа."},

			"recipient": {required: "Моля въведете име.", maxlength: "Въведохте повече от 255 символа."},
			"recipient_phone1": {required: "Моля въведете телефонен номер.", number: "Телефонът трябва да съдържа само цифри."},
			"recipient_town": {required: "Моле въведете град", maxlength: "Въведохте повече от 255 символа."},
			"recipient_note": {maxlength: "Въведохте повече от 255 символа."},

			"service": {required: "Моля изберете вид на услугата."},
			"package": {required: "Моля изберете тип на пратката.", maxlength: "Въведохте повече от 100 символа."},
			"package_count": {required: "Моля въведете брой пратки.", number: "Броят пратки трабва да съдържа само цифри."},
			"weight": {required: "Моля въведете тегло.", number: "Теглото трабва да съдържа само цифри."},
			"width": {number: "Полето трабва да съдържа само цифри."},
			"height": {number: "Полето трабва да съдържа само цифри."},
			"depth": {number: "Полето трабва да съдържа само цифри."},
			"contents": {required: "Моля въведете съдържание на пратката.", maxlength: "Въведохте повече от 255 символа."},
			"slow_shipment_date": {minDate: "Невалидна дата!"}
		},
		errorPlacement: function(error, element) {
			toastr.error(error.text());
			if (element.attr('type') === 'radio') {
				error.insertAfter('#'+element.attr('name'));
			}
			else {
				error.insertAfter(element);
			}
		}
	});
	
	//hide validation tip on click
	$("input[type=text], input[type=email], input[type=number], input[type=tel], textarea").focus(function(){
			$(".wpcf7-not-valid-tip").css('display','none');
	});

	$('.search').autocomplete({
		source: function( request, response ) {
			$.ajax({
				url : '/request/search',
				dataType: "json",
				data: {
					search: request.term
				},
				success: function( data ) {
					response( $.map( data, function( item ) {
						return {
							label: item.name + ', ' + (item.town != null ? item.town : 'празно') + ', ' + (item.area != null ? item.area : 'празно') + ', ' + (item.street != null ? item.street : 'празно') + ', ' + item.phone1,
							value: item.name,
							data : item
						}
					}));
				}
			});
		},

		autoFocus: true,          
		minLength: 2,
		select: function( event, ui ) {
			event.preventDefault();
			var m = event.target.id.match(/^(sender|recipient)/)
			var prefix = m[1]
			var data = ui.item.data;            
			$('#'+prefix).val(data.name);
			$('#'+prefix+'_town').val(data.town);
			$('#'+prefix+'_area').val(data.area);
			$('#'+prefix+'_street').val(data.street);
			$('#'+prefix+'_number').val(data.number);
			$('#'+prefix+'_building').val(data.building);
			$('#'+prefix+'_vhod').val(data.vhod);
			$('#'+prefix+'_floor').val(data.floor);
			$('#'+prefix+'_apartment_number').val(data.apartment_number);
			$('#'+prefix+'_note').val(data.note);
			$('#'+prefix+'_phone1').val(data.phone1);
		}
	});

	$(".search").autocomplete( "option", "appendTo", "#mod-form" );

	$(document).on('focus', '#sender_phone1, #sender, #sender_town, #sender_area, #sender_street, #sender_note, #recipient_phone1, #recipient, #recipient_town, #recipient_area, #recipient_street, #recipient_note', function(){
			$(this).attr('autocomplete', 'nope');
	});

	$('#service, #package_count, #weight, #cod, #receipt, #receipt_documents, #package').on('change', function() {
		$.ajax({
			url: '/request/price',
			dataType: "json",
			data: $('#mod-form').serialize(),
			success: function(data) {
				$('.price-section #price').html(data.price + " лв.");
			}
		});
	});

	$('#calc-service, #calc-package_count, #calc-weight, #calc-cod, #calc-receipt, #calc-receipt_documents, .calc-package').on('change', function() {
		$.ajax({
			url: '/request/price',
			dataType: "json",
			data: $('#calc-form').serialize(),
			success: function(data) {
				$('.modal-footer #calc-price').html(data.price + " лв.");
			}
		});
	});

	$('#expense').on('change', function() {
		if( $(this).val() == 'трето лице' )
			$('#expense_other_container').show();
		else
			$('#expense_other_container').hide();
	});

	$('.cancel-shipment').on('click', function() {
		var $btn = $(this);
		$btn.html($btn.html()+'...').attr('disabled', 'disabled');
		var id = $btn.data('id');
		$.ajax({
			url: '/references/'+id+'/cancel',
			type: 'POST',
			success: function(data) {
				if( data.canceled ) {
					$btn.html('Възстанови').removeAttr('disabled');
					$('td#status-f').html('анулирана');
				} else {
					$btn.html('Aнулирай').removeAttr('disabled');
					$('td#status-f').html('заявка');
				}
			}
		});
	});

	$('#choice').on('click', function(event) {
		event.preventDefault();
		$('.question').modal('hide');

		$('.question').on('hidden.bs.modal', function () {
	
			$('.request').modal('show');
		})
	});

	$('#promo-ship').on('click', function(event) {
		event.preventDefault();
		$('.promo').modal('hide');

		$('.promo').on('hidden.bs.modal', function () {
			$('.request').modal('show');
		})
	});

	$('#calc-btn').on('click', function(event) {
		event.preventDefault();
		$('#sender_town').val($('#calc_sender').val());
		$('#recipient_town').val($('#calc_receiver').val());
		$('#service').val($('#calc-service').val());
		$('#package_count').val($('#calc-package_count').val());
		$('#weight').val($('#calc-weight').val());
		$('#package').val($('input[name=package]:checked').val());
		$('#fragile').val($('input[name=fragile]:checked').val());
		if ($('#fragile').val() == "1") {
			$('#fragile').attr('checked', 'checked');
		}
		$('#receipt_documents').val($('input[name=receipt_documents]:checked').val());
		if ($('#receipt_documents').val() == "1") {
			$('#receipt_documents').attr('checked', 'checked');
		}
		$('#cod').val($('#calc-cod').val());
		$('#receipt').val($($('input[name=receipt]:checked')).val());
		if ($('#receipt').val() == "1") {
			$('#receipt').attr('checked', 'checked');
		}
		var calc_price = $('.modal-footer #calc-price').html();
		$('.price-section #price').html(calc_price);
		$('#pop-upCalculate').modal('hide');
		$('#pop-upCalculate').on('hidden.bs.modal', function () {
			$('.request').modal('show');
		})
	});

	var $findError = $('#mod-login').find('p');
		if (window.location.href.indexOf("/login") > -1 || window.location.href.indexOf("/register") > -1){
			if ($findError.hasClass('has-error')) {
				 $('.question').modal('hide');
			}
	}

	$('#mod-login').validate({
		rules: {
			'username': {required: true},
			'password': {required: true}
		},
		messages: {
			"username": {required: "Моля въведете име."},
			"password": {required: "Моля въведете парола."}
		}
	});

	$('#delivered_form').validate({
		rules: {
			'received_by': {required: true}
		},
		messages: {
			"received_by": {required: "Моля въведете име на получател."}
		},
		errorPlacement: function(error, element) {
			toastr.error(error.text());
		}
	});

	$('#courier').validate({
		rules: {
			'courier_id': {required: true}
		},
		messages: {
			"courier_id": {required: "Моля изберете куриер."}
		},
		errorPlacement: function(error, element) {
			toastr.error(error.text());
		}
	});

	$('#delivery_courier').validate({
		rules: {
			'delivery_courier_id': {required: true}
		},
		messages: {
			"delivery_courier_id": {required: "Моля изберете куриер."}
		},
		errorPlacement: function(error, element) {
			toastr.error(error.text());
		}
	});

	$('#mod-form').on('submit', function(event) {
		event.preventDefault();
		/* Act on the event */
		var path = $(this).attr('action'),
			data = $(this).serialize();
		$("#loading").fadeIn();
		$.ajax({
			url: path,
			type: 'POST',
			data: data,
			success: function(data) {
				console.log('success');
				$("#loading").fadeOut();
				$('#pop-upQuery').modal('hide');
				$('.request').modal('hide');
				toastr.success('Заявката е изпратена успешно.');
				$('#mod-form')[0].reset();
				if(window.location.href.indexOf("/shipments") > -1) {
					sessionStorage.setItem("Status",window.location.href.indexOf("/shipments") > -1)	
					location.reload();
				}
			},
			error: function(error){
				$("#loading").fadeOut();
				toastr.error(error);
			}
		})
	});

	if(sessionStorage.getItem("Status"))
		{
				toastr.success('Заявката е изпратена успешно.');
				sessionStorage.clear();
		}

	$("#sender, #sender_note, #sender_phone1, #recipient, #recipient_phone1, #recipient_note, #sender_town, #recipient_town, #sender_area, #recipient_area, #sender_street, #recipient_street, #sender_number, #recipient_number, #sender_building, #recipient_building, #sender_vhod, #recipient_vhod, #sender_floor, #recipient_floor, #sender_apartment_number, #recipient_apartment_number, #package_count, #weigth, #width, #depth, #height, #contents, #cod, #insurance, #notes").keypress(function(e) {
			//Enter key
			if (e.which == 13) {
				return false;
			}
	});	

	$('#shipment-rec input[type=checkbox]').on('change', function() {
		
		var datapost = $('#shipment-rec').serialize();
		var shipment_id = $(this).data('shipment');
		$.ajax({
			url: '/courier/' + shipment_id + '/received',
			type: 'GET',
			data: datapost,
			success: function(data){
				console.log('success');
				$('#head-status span').html('взета').css('color', '#f47c36');
				$('#shipment-rec input').attr('disabled', 'true');
				$('#shipment-rec').fadeOut();
				$('#delivered_form').fadeIn();
				location.reload();
			}
		})
	});

	$('#delivered_form').on('submit', function(event) {
		event.preventDefault();

		var url = $(this).attr('action');
		var datapost = $(this).serialize();
		$.ajax({
			url: url,
			type: 'GET',
			data: datapost,
			success: function(data){
				console.log('success');
				$('#head-status span').html('доставена').css('color', '#f47c36');
				toastr.success('Заявката е маркирана като доставена');
				$('#date-c').removeClass('ds-n');
				$('#cancel-form').hide('slow');
				$('#delivery-btn').attr('disabled', 'true');
			}, 
			error: function(error){
				toastr.error(error);
			}

		})
	});

	$('#service').change(function() {
		if($('#service').val() == '24 часа') {
			$('#label_date').show('slow');
		} else {
			$('#label_date').hide('slow');
		}
	 });

		$(function () {
			$('#slow_shipment_date').datetimepicker()
	});
	
	function readURL(input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function(e) {
				$('#img').attr('src', e.target.result);
			}
			reader.readAsDataURL(input.files[0]);
		}
	}
	
	$("#images").change(function() {
		readURL(this);
	});

	var towns = ['Айтос',
      'Априлци',
      'Ардино',
      'Асеновград',
      'Ахелой',
      'Ахтопол',
      'Балчик',
      'Банкя',
      'Банско',
      'Баня',
      'Батак',
      'Батановци',
      'Белене',
      'Белица',
      'Белово',
      'Белоградчик',
      'Берковица',
      'Благоевград',
      'Бобов дол',
      'Бобошево',
      'Божурище',
      'Бойчиновци',
      'Болярово',
      'Борово',
      'Ботевград',
      'Брацигово',
      'Брегово',
      'Брезник',
      'Брезово',
      'Брусарци',
      'Бургас',
      'Бухово',
      'Българово',
      'Бяла Слатина',
      'Бяла черква',
      'Бяла',
      'Бял',
      'Варна',
      'Велики Преслав',
      'Велико Търново',
      'Велинград',
      'Ветово',
      'Ветрен',
      'Видин',
      'Враца',
      'Вълчедръм',
      'Вълчи',
      'Върбица',
      'Вършец',
      'Габрово',
      'Генерал Тошево',
      'Глоджево',
      'Годеч',
      'Горна Оряховица',
      'Грамада',
      'Гулянци',
      'Гурково',
      'Гълъбово',
      'Две могили',
      'Дебелец',
      'Девин',
      'Девня',
      'Джебел',
      'Димитровград',
      'Димов',
      'Добринище',
      'Добрич',
      'Доспат',
      'Дряново',
      'Дулово',
      'Дунавци',
      'Дупница',
      'Дългопол',
      'Елена',
      'Елин Пелин',
      'Елхово',
      'Етрополе',
      'Завет',
      'Земен',
      'Златарица',
      'Златица',
      'Златоград',
      'Ивайловград',
      'Искър',
      'Исперих',
      'Ихтиман',
      'Каблешк',
      'Каварна',
      'Казанлък',
      'Калофер',
      'Камено',
      'Каолиново',
      'Карлово',
      'Карнобат',
      'Каспичан',
      'Кермен',
      'Килифарево',
      'Китен',
      'Клисура',
      'Кнежа',
      'Козлодуй',
      'Койнаре',
      'Копривщица',
      'Костандово',
      'Костенец',
      'Костинброд',
      'Котел',
      'Кочериново',
      'Кресна',
      'Криводол',
      'Кричим',
      'Крумовград',
      'Кубрат',
      'Куклен',
      'Кула',
      'Кърджали',
      'Кюстендил',
      'Левски',
      'Летница',
      'Ловеч',
      'Лозница',
      'Лом',
      'Луковит',
      'Лъки',
      'Любимец',
      'Лясковец',
      'Мадан',
      'Маджарово',
      'Малко Търново',
      'Мездра',
      'Мелник',
      'Меричлери',
      'Момин проход',
      'Момчилград',
      'Монтана',
      'Неделино',
      'Несебър',
      'Николаево',
      'Никопол',
      'Нова Загора',
      'Нови пазар',
      'Обзор',
      'Омуртаг',
      'Опака',
      'Оряхово',
      'Павел баня',
      'Павликени',
      'Пазарджик',
      'Панагюрище',
      'Перник',
      'Перущица',
      'Петрич',
      'Пещера',
      'Пирдоп',
      'Плачковци',
      'Плевен',
      'Плиска',
      'Пловдив',
      'Полски Тръмбеш',
      'Поморие',
      'Пордим',
      'Правец',
      'Приморско',
      'Провадия',
      'Първомай',
      'Раднево',
      'Радомир',
      'Разград',
      'Разлог',
      'Ракитово',
      'Раковски',
      'Рила',
      'Роман',
      'Рудозем',
      'Русе',
      'Садово',
      'Самоков',
      'Сандански',
      'Сапарева баня',
      'Свети Влас',
      'Свиленград',
      'Свищов',
      'Своге',
      'Севлиево',
      'Сеново',
      'Септември',
      'Силистра',
      'Симеоновград',
      'Симитли',
      'Славяново',
      'Сливен',
      'Сливница',
      'Смолян',
      'Смядово',
      'Созопол',
      'Сопот',
      'София',
      'Средец',
      'Стамболийски',
      'Стара Загора',
      'Стралджа',
      'Стрелча',
      'Суворово',
      'Сунгурларе',
      'Сухиндол',
      'Съединение',
      'Сърница',
      'Твърдица',
      'Тервел',
      'Тетевен',
      'Тополов',
      'Трън',
      'Тръстеник',
      'Трявна',
      'Тутракан',
      'Угърчин',
      'Хаджидимово',
      'Харманли',
      'Хасково',
      'Хисаря',
      'Цар Калоян',
      'Червен бряг',
      'Черноморец',
      'Чипровци',
      'Чирпан',
      'Шивачево',
      'Шипка',
      'Шумен',
      'Ябланица',
      'Якоруда',
      'Ямбол'
	];

	$(".calc").autocomplete({
		appendTo: "#calc-form",
		source: towns
	});

	$(document).on('focus', '#calc_sender', '#calc_receiver', function(){
			$(this).attr('autocomplete', 'nope');
	});

	$('#calc_date').datetimepicker();


	$.fn.inputFilter = function(inputFilter) {
	    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
	      if (inputFilter(this.value)) {
	        this.oldValue = this.value;
	        this.oldSelectionStart = this.selectionStart;
	        this.oldSelectionEnd = this.selectionEnd;
	      } else if (this.hasOwnProperty("oldValue")) {
	        this.value = this.oldValue;
	        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
	      }
	    });
	};
	$("#track-search").inputFilter(function(value) {
		return /^-?\d*$/.test(value);
	});

	$('#cod').decimalMask('999999999999.99');
	$('#insurance').decimalMask('999999999999.99');
	$('#calc-cod').decimalMask('999999999999.99');
//jQuery ready() end	
});
